<template>
        <div class="web-width">
            <div style="text-align: center;font-size: 20px;margin-bottom: 2%">
                <h1>重置密码</h1>
            </div>
           <div style="width: 800px;margin:0 auto;">
             <el-form ref="resetpasswordForm" :model="resetpasswordForm" :rules="rules" label-width="80px" style="width: 700px; margin:0 auto; margin-left: 120px">
               <el-form-item label="账号" prop="value" style="margin-bottom:20px">
                 <el-input v-model="resetpasswordForm.value"
                           style="width:400px;"
                 ></el-input>
               </el-form-item>
               <el-form-item label="密码" prop="password" style="margin-bottom:20px">
                 <el-input v-model="resetpasswordForm.password"
                           style="width:400px;"
                 ></el-input>
               </el-form-item>
               <el-form-item label="确认密码" prop="newPassword2" style="margin-bottom:20px">
                 <el-input v-model="resetpasswordForm.newPassword2"
                           style="width:400px;"
                 ></el-input>
               </el-form-item>

               <el-form-item>
                 <el-button style="width:400px" type="primary" @click="next">提交</el-button>
               </el-form-item>
             </el-form>
           </div>
        </div>
</template>

<script>
import {forgetPassword, rePassword, validPassword} from "../../api/metadata";

  export default {
    name: "resetpassword",
    data(){
      /*var regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z0-9_]{6,16}$/;*/
      /*var regex = /^(?!([a-zA-Z]+|\d+)$)[a-zA-Z\d]{6,20}$/;*/
      var regex = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z_]{6,18}$/;
      var validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码'));
        }
          // else if (this.form.checkPass !== '') {
          //     this.$refs.form.validateField('checkPass');
        // }
        else if (!regex.test(value)) {
          callback(new Error("密码须同时包含字母和数字且至少6位"));
        }
        callback();
      };
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.resetpasswordForm.password) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
      return{
        resetpasswordForm:{
          value:'',
          password:'',
          newPassword2:'',
        },
        rules:{
          value: [
            {
              required: true,
              message: "请输入账号(电子邮箱)",
              trigger: "blur",

            },
            {  type: "email", message: "请输入正确的邮箱地址", trigger: ['blur', 'change'], },
          ],
          password: [
            { required: true, message: "请输入密码", trigger: "change" },
            { validator: validatePass, trigger: 'blur' }
          ],
          newPassword2: [
            { required: true, validator: validatePass2, trigger: ['blur', 'change'], },

          ],
        }
      };

    },
    methods: {
      next() {
        rePassword(this.resetpasswordForm).then(res=>{
          if(res.error_code==200){
            //console.log(res)
            this.$alert('临时密码已发送到您的邮箱，请尽快登录，在个人中心-我的信息-修改密码', '重置成功', {
              confirmButtonText: '确定',
            })
            this.$message.success('密码更改成功')
            this.$router.push("/login")
          }else{
           // console.log(res)
          }
        })
      }
  }

  }
</script>

<style scoped>
    *{margin: 0;padding: 0;border: 0;}
    /*el-form{width:200px;margin-left: 27%;margin-bottom: 25px;}*/
    .web-width{width:1200px;margin:150px auto;}
</style>